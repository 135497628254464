
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import type { ReportingCallsStatsFullLine } from '@/definitions/reporting/messagesCalls/calls/types'

export default defineComponent({
  components: {
    PageContent,
  },
  setup() {
    const statsFull = ref<ReportingCallsStatsFullLine[]>([
      { year: 2013, totalCalls: '1,445', totalTalkTime: '12:33:18', costs: '$ 12,993.00' },
      { year: 2014, totalCalls: '2,491', totalTalkTime: '05:30:21', costs: '$ 33,138.00 ' },
      { year: 2015, totalCalls: '1,223', totalTalkTime: '23:18:33', costs: '$ 13,441.00' },
      { year: 2016, totalCalls: '999', totalTalkTime: '13:11:35', costs: '$ 25,451.00' },
      { year: 2018, totalCalls: '826', totalTalkTime: '05:30:21', costs: '$ 11,180.00' },
      { year: 2019, totalCalls: '6,316', totalTalkTime: '13:11:35', costs: '$ 65,670.00' },
      { year: 2020, totalCalls: '893', totalTalkTime: '02:33:48', costs: '$ 32,448.00' },
      { year: 2021, totalCalls: '1,931', totalTalkTime: '12:33:18', costs: '$ 30,397.00' },
      { year: 2022, totalCalls: '757', totalTalkTime: '23:18:33', costs: '$ 23,217.00' },
    ])

    return {
      statsFull,
    }
  },
})
